<template>
  <BaseDialog
    ref="base-dialog"
    :max-width="512"
    :header-title="headerTitle"
    :is-loading="isLoading"
    :footer-buttons="footerButtons"
    @close="$emit('close')"
  >
    <v-form ref="form" class="grid grid-cols-2 gap-4" :disabled="isLoading">
      <v-autocomplete
        v-model="form.uriTypeId"
        class="col-span-2"
        background-color="white"
        label="Selected link type"
        item-text="name"
        item-value="id"
        clearable
        outlined
        dense
        :loading="uriTypes.isLoading"
        :items="uriTypes.list"
        :rules="[$rules.required]"
      />

      <v-text-field
        v-model.trim="form.fullUrl"
        class="col-span-2"
        label="Full URL (e.g., https://example.com)"
        placeholder="https://example.com"
        background-color="white"
        clearable
        outlined
        dense
        :rules="[$rules.required, $rules.url]"
      />

      <v-textarea
        v-model.trim="form.description"
        class="col-span-2"
        label="Description"
        background-color="white"
        clearable
        outlined
        counter="500"
        dense
        :rules="[$rules.required, value => $rules.counter(value, 500)]"
      />
    </v-form>
  </BaseDialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import * as notify from '@/utils/notify'
import { defineIsEqual } from '@/utils/base'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'

const defaultEmptyForm = Object.freeze({
  uriTypeId: '',
  fullUrl: '',
  description: ''
})

export default {
  name: 'TenantMemberProfileLinksCreationDialog',

  components: { BaseDialog },

  props: {
    principal: { type: Object, required: true },
    item: { type: Object, default: null }
  },

  data: () => ({
    form: structuredClone(defaultEmptyForm),

    isLoading: false
  }),

  computed: {
    ...mapState('uris', ['uris', 'uriTypes']),

    isEditing() {
      return Boolean(this.item)
    },

    isCreating() {
      return !this.isEditing
    },

    isModified() {
      return !defineIsEqual(this.defaultForm, this.form)
    },

    headerTitle() {
      return this.isCreating ? 'Add new link' : 'Update link'
    },

    footerButtons() {
      return [
        {
          text: 'Cancel',
          attrs: { color: 'grey', text: true },
          handler: this.handleClose
        },
        {
          text: this.isCreating ? 'Add' : 'Update',
          attrs: {
            color: 'primary',
            disabled: !this.isModified,
            loading: this.isLoading
          },
          handler: this.handleSubmit
        }
      ].filter(Boolean)
    }
  },

  created() {
    this.handleRefresh()
    this.setInitialForm()
  },

  methods: {
    ...mapActions('uris', {
      fetchAllUriTypes: 'FETCH_ALL_URI_TYPES',
      saveOne: 'SAVE_ONE'
    }),

    handleRefresh() {
      return this.fetchAllUriTypes()
    },

    async handleSubmit() {
      const isValid = this.defineIsValid()

      if (!isValid) return

      const payload = this.definePayload()

      this.setIsLoading(true)

      const { isSuccess } = await this.saveOne(payload)

      this.setIsLoading(false)

      if (isSuccess) {
        this.handleSuccess()
      }
    },

    handleSuccess() {
      const title = this.isCreation ? 'Link created' : 'Link updated'

      notify.success({ title })
      this.$emit('success')

      this.handleClose()
    },

    handleClose() {
      this.$refs['base-dialog'].handleClose()
    },

    defineIsValid() {
      const isValid = this.$refs.form.validate()

      if (!isValid) {
        notify.error({ title: 'Please fill in all the required fields' })
      }

      return isValid
    },

    definePayload() {
      const payload = {
        ...(this.isEditing ? this.item : {}),

        principalId: this.principal.id,
        userTypeCode: this.principal.userTypeCode,

        uriTypeId: this.form.uriTypeId,
        value: this.form.fullUrl,
        description: this.form.description
      }

      delete payload.$type

      return payload
    },

    setInitialForm() {
      this.form = this.isEditing
        ? {
            uriTypeId: this.item.userUriTypeId,
            fullUrl: this.item.value,
            description: this.item.description
          }
        : structuredClone(defaultEmptyForm)

      this.defaultForm = structuredClone(this.form)
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading
    }
  }
}
</script>
