import Vue from 'vue'
import ws from '@/utils/web-storage'

import $api from '@/api'
import router from '@/router'

import { LS_KEYS, USER_TYPES } from '@/utils/consts'
import * as notify from '@/utils/notify'
import * as services from '@/services'
import { wireService } from '@/utils/signalr'

const defineAccessToken = () => ws.get(LS_KEYS.ACCESS_TOKEN) || null

export default {
  namespaced: true,

  state: {
    accessToken: defineAccessToken(),
    isPreAuthorized: false,
    password: null
  },

  getters: {
    isAuthorized: (state, _, rootState) => {
      if (!state.accessToken) return false
      if (rootState.user.user === null) return null

      return true
    }
  },

  actions: {
    async AUTHENTICATE_LIGHT({ commit, dispatch }, payload) {
      const { user } = await services.auth.authenticateLight(payload)

      if (!user) return

      await Promise.all([
        commit('user/SET_USER', user, { root: true }),
        commit('SET_IS_PRE_AUTHORIZED', true),
        commit('SET_PASSWORD', payload.password)
      ])

      if (user.isFake) {
        commit(
          'auth/SET_ACCESS_TOKEN',
          {
            accessToken: user.token,
            refreshTokenExpiresAt: user.refreshTokenExpiresAt
          },
          { root: true }
        )

        await dispatch('auth/FETCH_ONE_USER_BY_ACCESS_TOKEN', null, {
          root: true
        })

        router.push({ name: 't.home' })

        return
      }

      const is2FA = Boolean(user.twoFactorAuthenticationOptionId)

      if (is2FA) {
        const optionId = user.twoFactorAuthenticationOptionId
        const phoneNumber = user.twoFactorAuthenticationPhone

        router.push({
          name: 't.mfa.verification',
          params: { optionId, phoneNumber }
        })

        return
      }

      router.push({ name: 't.mfa.config' })
    },

    async FETCH_ONE_USER_BY_ACCESS_TOKEN({ commit, rootGetters, dispatch }) {
      let result = {}
      const accessToken = defineAccessToken()

      if (!accessToken) return result

      try {
        result = await $api.users.fetchOneByAccessToken({ accessToken })
        const { user, error } = result

        if (error) {
          dispatch('LOGOUT')
          return result
        }

        if (defineAccessToken()) {
          await commit('user/SET_USER', user, { root: true })

          const isUserRole = rootGetters['user/defineIsUserRole'](user)

          Vue.prototype.$gtag?.set('user_properties', {
            user_role: isUserRole.anyAdmin ? 'Admin' : 'User',
            user_type: USER_TYPES.labelByCode[user.userTypeCode]
          })

          if (user) {
            wireService.start({ user })
          }

          await dispatch('principal/contracts/FETCH_ALL', user, { root: true })
        }
      } catch (error) {
        console.error(error)
        dispatch('LOGOUT')
      }

      return result
    },

    CHECK_IS_USER_AUTHORIZED({ dispatch }) {
      const accessToken = defineAccessToken()
      if (!accessToken) return

      return dispatch('FETCH_ONE_USER_BY_ACCESS_TOKEN')
    },

    CHECK_IS_REFRESH_TOKEN_VALID({ getters, dispatch }) {
      if (!getters.isAuthorized) return

      const accessToken = defineAccessToken()

      if (!accessToken) dispatch('LOGOUT')
    },

    async REQUEST_PASSWORD_RESET(_, payload) {
      try {
        const { isSuccess, error } = await $api.auth.requestResetByEmail(
          payload
        )

        if (error) {
          notify.error({ text: error })
          return
        }

        return isSuccess
      } catch (error) {
        notify.error({ text: error })
      }

      return false
    },

    async RESET_PASSWORD(_, payload) {
      try {
        const { isSuccess, error } = await $api.auth.resetPassword(payload)

        if (error) {
          notify.error({ text: error })
          return
        }

        notify.success({ title: 'The password has been changed' })
        return isSuccess
      } catch (error) {
        notify.error({ text: error })
      }

      return false
    },

    async LOGOUT({ commit }) {
      console.trace()

      router.push({ name: 't.auth.login' })

      await Promise.all([
        commit('SET_IS_PRE_AUTHORIZED', false),
        commit('CLEAR_ACCESS_TOKEN'),
        commit('user/SET_USER', null, { root: true }),
        // commit('tenants/SET_SELECTED', null, { root: true }),
        commit('admin/tenants/SET_SELECTED', [], { root: true })
      ])

      wireService.stop()

      // themeChanger.setDefaultTheme()

      ws.clear()
    }
  },

  mutations: {
    SET_IS_PRE_AUTHORIZED(state, isPreAuthorized) {
      state.isPreAuthorized = isPreAuthorized
    },
    SET_PASSWORD(state, password) {
      state.password = password
    },
    SET_ACCESS_TOKEN(state, { accessToken, refreshTokenExpiresAt }) {
      state.accessToken = accessToken

      const expiresAt = new Date(refreshTokenExpiresAt).getTime()

      ws.set(LS_KEYS.ACCESS_TOKEN, accessToken, { expiresAt })
    },
    CLEAR_ACCESS_TOKEN(state) {
      state.accessToken = null

      ws.remove(LS_KEYS.ACCESS_TOKEN)
    }
  }
}
