<template>
  <div>
    <v-card>
      <v-card-title class="w-full gap-4 flex justify-between">
        <span>{{ headerTitle }}</span>

        <v-btn
          v-if="$breakpoints.isMobileAndDown"
          icon
          color="primary"
          :disabled="uris.isLoading"
          @click="() => handleOpenCreationDialog(null)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          v-else
          color="primary"
          :disabled="uris.isLoading"
          @click="() => handleOpenCreationDialog(null)"
        >
          <v-icon left>mdi-plus</v-icon>
          <span>Add</span>
        </v-btn>
      </v-card-title>

      <v-card-subtitle v-if="headerSubtitle">
        {{ headerSubtitle }}
      </v-card-subtitle>

      <v-divider />

      <v-card-text class="p-0">
        <MemberProfileLinksList
          v-if="uris.list.length > 0 || uris.isLoading"
          class="max-w-screen-md w-full"
          :list="uris.list"
          :is-loading="uris.isLoading"
          @action-update="handleOpenCreationDialog"
          @action-delete="handleDelete"
        />

        <BaseEmpty v-else title="Empty" :subtitle="['You have no links yet']" />
      </v-card-text>
    </v-card>

    <MemberProfileLinksCreationDialog
      v-if="creationDialog.isVisible"
      :principal="principal"
      :item="creationDialog.item"
      @success="handleSuccess"
      @close="handleCloseCreationDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import * as notify from '@/utils/notify'
import * as confirm from '@/utils/confirm'

import BaseEmpty from '@/components/Base/BaseEmpty.vue'

import MemberProfileLinksList from '@/components/Tenant/Member/MemberProfile/MemberProfileLinks/MemberProfileLinksList.vue'
import MemberProfileLinksCreationDialog from '@/components/Tenant/Member/MemberProfile/MemberProfileLinks/MemberProfileLinksCreationDialog.vue'

export default {
  name: 'MemberProfileLinks',

  components: {
    BaseEmpty,

    MemberProfileLinksList,
    MemberProfileLinksCreationDialog
  },

  props: {
    headerTitle: { type: String, default: 'Personal Links' },
    headerSubtitle: { type: String, default: '' },

    principal: { type: Object, required: true }
  },

  data: () => ({
    creationDialog: {
      isVisible: false,
      item: null
    }
  }),

  computed: {
    ...mapState('uris', ['uris'])
  },

  created() {
    this.handleRefresh()
  },

  methods: {
    ...mapActions('uris', {
      fetchAllByPrincipal: 'FETCH_ALL_BY_PRINCIPAL',
      deleteOne: 'DELETE_ONE'
    }),

    handleRefresh() {
      return this.fetchAllByPrincipal(this.principal)
    },

    async handleOpenCreationDialog(item) {
      this.creationDialog = { isVisible: true, item }
    },

    handleCloseCreationDialog() {
      this.creationDialog = { isVisible: false, item: null }
    },

    async handleDelete(item) {
      const text = `Are you sure you want to delete "${item.userUriTypeName} (${item.value})" link?`
      const { isConfirmed } = await confirm.warning({ text })

      if (!isConfirmed) return

      const payload = {
        principal: this.principal,
        id: item.id
      }

      const { isSuccess } = await this.deleteOne(payload)

      if (!isSuccess) return

      notify.success({ title: 'Link deleted' })
      this.$emit('updated')
    },

    handleSuccess() {
      this.handleRefresh()
      this.$emit('updated')
    }
  }
}
</script>
