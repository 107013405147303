import * as services from '@/services'

export default {
  namespaced: true,

  state: {
    budget: {
      assignmentId: null,
      content: null,

      isLoading: false,

      rows: {
        list: []
      },

      ui: {
        createBudgetDialog: { isVisible: false },
        createPayoutDialog: { isVisible: false, payout: null }
      }
    }
  },

  actions: {
    async FETCH_ALL({ commit, state }) {
      await Promise.all([
        commit('SET_IS_LOADING', true),
        commit('SET_CONTENT', null),
        commit('SET_ROWS_LIST', [])
      ])

      const { list } = await services.assignmentBudget.fetchAllByAssignmentId({
        assignmentId: state.budget.assignmentId
      })

      const content = list[0]

      let payoutsResult = {}

      if (content) {
        payoutsResult =
          await services.assignmentPayouts.fetchAllByAssignmentBudgetId({
            assignmentBudgetId: content.id
          })
      }

      await Promise.all([
        commit('SET_CONTENT', content || null),
        commit('SET_ROWS_LIST', payoutsResult.list || []),
        commit('SET_IS_LOADING', false)
      ])
    },

    async CREATE_BUDGET({ commit, state, dispatch }) {
      await commit('SET_IS_LOADING', true)

      const { content, isSuccess } = await services.assignmentBudget.createOne({
        assignmentId: state.budget.assignmentId
      })

      await Promise.all([
        commit('SET_IS_LOADING', false),
        commit('SET_CONTENT', content)
      ])

      await dispatch('FETCH_ALL')

      return { isSuccess }
    }
  },

  mutations: {
    SET_ASSIGNMENT_ID(state, assignmentId) {
      state.budget.assignmentId = assignmentId
    },
    SET_CONTENT(state, content) {
      state.budget.content = content
    },

    SET_IS_LOADING(state, isLoading) {
      state.budget.isLoading = isLoading
    },

    SET_ROWS_LIST(state, list) {
      state.budget.rows.list = list
    },

    UPDATE_UI(state, toUpdate) {
      state.budget.ui = { ...state.budget.ui, ...toUpdate }
    }
  }
}
