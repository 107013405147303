<template>
  <div class="gap-4 flex flex-col">
    <BudgetContent />

    <BudgetDialogs @refresh="handleRefresh" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import BudgetContent from '@/components/Admin/JobManager/Budget/BudgetContent.vue'
import BudgetDialogs from '@/components/Admin/JobManager/Budget/BudgetDialogs.vue'

export default {
  name: 'AdminJobManagerBudget',

  components: { BudgetContent, BudgetDialogs },

  props: {
    assignmentId: { type: Number, required: true }
  },

  async created() {
    await this.setAssignmentId(this.assignmentId)

    this.handleRefresh()
  },

  methods: {
    ...mapActions('admin/jobManager/budget', {
      fetchAll: 'FETCH_ALL'
    }),
    ...mapMutations('admin/jobManager/budget', {
      setAssignmentId: 'SET_ASSIGNMENT_ID'
    }),

    handleRefresh() {
      this.fetchAll()
    }
  }
}
</script>
