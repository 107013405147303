<template>
  <BudgetCreateDialog
    v-if="budget.ui.createBudgetDialog.isVisible"
    :budget="budget.content"
    :assignment-id="budget.assignmentId"
    @success="$emit('refresh')"
    @close="() => updateUi({ createBudgetDialog: { isVisible: false } })"
  />

  <BudgetCreatePayoutDialog
    v-else-if="budget.ui.createPayoutDialog.isVisible"
    :budget="budget.content"
    :payout="budget.ui.createPayoutDialog.payout"
    @success="$emit('refresh')"
    @close="() => updateUi({ createPayoutDialog: { isVisible: false } })"
  />
</template>

<script>
import { mapState, mapMutations } from 'vuex'

const BudgetCreateDialog = () =>
  import('@/components/Admin/JobManager/Budget/BudgetCreateDialog.vue')
const BudgetCreatePayoutDialog = () =>
  import('@/components/Admin/JobManager/Budget/BudgetCreatePayoutDialog.vue')

export default {
  name: 'AdminJobManagerBudgetDialogs',

  components: { BudgetCreateDialog, BudgetCreatePayoutDialog },

  computed: {
    ...mapState('admin/jobManager/budget', ['budget'])
  },

  methods: {
    ...mapMutations('admin/jobManager/budget', {
      updateUi: 'UPDATE_UI'
    })
  }
}
</script>
