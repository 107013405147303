import axios from 'axios'

import { formatResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/work-function`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-all`,
    FETCH_ALL_BY_ID: `/get-by-id/`,

    CREATE_ONE: `/create`,
    UPDATE_ONE: `/update`,
    DELETE_ONE: `/delete/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },

  async fetchAllById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_ID}${id}`)

    return formatListResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatResponse(res)
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  }
}
