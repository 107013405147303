<template>
  <BaseDialog
    ref="base-dialog"
    :max-width="1024"
    header-title="More info"
    :is-loading="isLoading"
    :footer-buttons="footerButtons"
    @close="handleForceClose"
  >
    <div class="gap-4 flex flex-col md:flex-row">
      <div>
        <v-card class="sticky top-0 flex flex-initial flex-shrink-0">
          <BaseProfilePrincipalTabs
            :value="activeTabCode"
            :tabs="tabs"
            :is-vertical="false"
            @select="handleSelectTab"
          />
        </v-card>
      </div>

      <div v-if="user" class="w-full gap-4 flex flex-col">
        <component
          :is="activeTab.component"
          :user="user"
          v-bind="activeTab.props"
          @updated="handleRefreshAfterClose"
        />
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { mapActions } from 'vuex'

import { TABS } from '@/components/Tenant/Member/MemberProfile/MemberProfile.vue'

import BaseDialog from '@/components/Base/BaseDialog/BaseDialog.vue'
import BaseProfilePrincipalTabs from '@/components/Base/BaseProfilePrincipalTabs.vue'

const TabsPersonalInfo = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsPersonalInfo.vue')
import TabsLinks from '@/components/Tenant/Member/MemberProfile/Tabs/TabsLinks.vue'
const TabsAvailability = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsAvailability.vue')
const TabsRates = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsRates.vue')

const TabsComments = () =>
  import('@/components/Tenant/Member/MemberProfile/Tabs/TabsComments.vue')

export default {
  name: 'AdminJobManagerBudgetContentListItemDialog',

  components: {
    BaseDialog,
    BaseProfilePrincipalTabs,

    TabsPersonalInfo,
    TabsLinks,
    TabsAvailability,
    TabsRates,
    TabsComments
  },

  props: {
    userId: { type: Number, required: true },
    initialTab: { type: String, default: TABS.codes.PERSON_INFO }
  },

  data: () => ({
    isRefreshAfterClose: false,
    isLoading: false,
    user: null,
    activeTabCode: TABS.codes.PERSON_INFO
  }),

  computed: {
    activeTab() {
      return (
        this.tabs.find(tab => tab.code === this.activeTabCode) || this.tabs[0]
      )
    },

    tabs() {
      return [
        {
          code: TABS.codes.PERSON_INFO,
          text: TABS.labels.PERSON_INFO,
          icon: 'mdi-account-circle-outline',
          component: 'TabsPersonalInfo',
          props: { isPasswordChange: false, isMfa: false }
        },
        {
          code: TABS.codes.PORTFOLIO_LINKS,
          text: TABS.labels.PORTFOLIO_LINKS,
          icon: 'mdi-file-link-outline',
          component: 'TabsLinks'
        },
        {
          code: TABS.codes.AVAILABILITY,
          text: TABS.labels.AVAILABILITY,
          icon: 'mdi-calendar-month-outline',
          component: 'TabsAvailability'
        },
        {
          code: TABS.codes.RATES,
          text: TABS.labels.RATES,
          icon: 'mdi-cash',
          component: 'TabsRates'
        },
        {
          code: TABS.codes.COMMENTS,
          text: TABS.labels.COMMENTS,
          icon: 'mdi-comment-multiple-outline',
          component: 'TabsComments'
        }
      ].filter(Boolean)
    },

    footerButtons() {
      return [
        {
          appendIcon: 'mdi-open-in-new',
          text: `Open user page`,
          attrs: { color: 'grey', text: true },
          handler: this.handleOpenUserPage
        },
        {
          text: 'Close',
          attrs: { color: 'primary' },
          handler: this.handleClose
        }
      ].filter(Boolean)
    }
  },

  created() {
    this.activeTabCode = this.initialTab

    this.handleRefresh()
  },

  methods: {
    ...mapActions('admin/users', {
      fetchOneUserById: 'FETCH_ONE_BY_ID'
    }),

    async handleRefresh() {
      this.setIsLoading(true)

      const { item } = await this.fetchOneUserById({ id: this.userId })

      this.setIsLoading(false)

      this.user = item
    },

    handleSelectTab({ code }) {
      this.activeTabCode = code
    },

    handleOpenUserPage() {
      this.$router.open({
        name: 'admin.people.id',
        params: { id: this.userId }
      })
    },

    handleRefreshAfterClose() {
      this.isRefreshAfterClose = true
    },

    handleForceClose() {
      this.$emit('close')

      if (this.isRefreshAfterClose) {
        this.$emit('refresh')
      }
    },

    handleClose() {
      this.$refs['base-dialog'].handleClose()

      if (this.isRefreshAfterClose) {
        this.$emit('refresh')
      }
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading
    }
  }
}
</script>
