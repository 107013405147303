<template>
  <v-app-bar
    id="app-header"
    app
    class="relative max-w-screen-3xl w-full mx-auto !bg-white !z-10"
  >
    <div
      v-if="$isOn.principalPage && tenant"
      class="absolute bottom-0 left-0 h-1 w-full"
      :style="`background: linear-gradient(45deg, ${tenant?.colors?.primary}, ${tenant?.colors?.secondary})`"
    />

    <div class="w-full h-full pb-1 flex items-center justify-between">
      <div class="w-full flex items-center">
        <div class="gap-4 flex flex-initial items-center">
          <BaseLogo
            :tenant-marker="tenant?.marker"
            :size="48"
            :to="{
              name: $isOn.adminPage ? 'admin.home' : 't.home',
              params: { tenantMarker: tenant?.marker }
            }"
          />

          <div class="hidden flex-col lg:flex">
            <span class="font-bold">
              Hi, {{ isMember ? user.firstName : user.hubName }}.
            </span>
            <div v-if="tenant" class="gap-1 inline-flex items-end">
              <span class="hidden md:flex">Welcome back to</span>
              <span>{{ tenant.name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-shrink-0 flex-initial items-center">
        <template v-if="isTenant && isAccountComplete">
          <AppHeaderBaseMenu :list="menu.profileList">
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="$breakpoints.isTabletAndDown"
                icon
                color="black"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-settings</v-icon>
              </v-btn>

              <v-btn v-else text v-bind="attrs" v-on="on">
                <span>My Profile</span>
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
          </AppHeaderBaseMenu>

          <template v-if="isMember">
            <v-btn
              v-if="$breakpoints.isTabletAndDown"
              icon
              color="black"
              exact-path
              :to="{ name: 't.member.projects' }"
            >
              <v-icon>mdi-briefcase</v-icon>
            </v-btn>

            <v-btn v-else text :to="{ name: 't.member.projects' }" exact-path>
              <span>Projects</span>
            </v-btn>
          </template>

          <v-divider class="mx-4 hidden sm:flex" vertical />

          <AppHeaderNotifs />
        </template>

        <AppHeaderBaseMenu :list="menu.commonPrincipalList">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              dense
              color="black"
              title="Member Menu"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
        </AppHeaderBaseMenu>

        <AppHeaderBaseMenu
          v-if="isUserRole.anyAdmin"
          :list="menu.adminList"
          title="Admin Menu"
        >
          <template #activator="{ on, attrs }">
            <v-btn icon dense color="accent" v-bind="attrs" v-on="on">
              <v-icon>mdi-shield-key-outline</v-icon>
            </v-btn>
          </template>
        </AppHeaderBaseMenu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { TABS as MEMBER_TABS } from '@/components/Tenant/Member/MemberProfile/MemberProfile.vue'
import { TABS as HUB_TABS } from '@/components/Tenant/Hub/HubProfile/HubProfile.vue'

import BaseLogo from '@/components/Base/BaseLogo.vue'
import AppHeaderBaseMenu from '@/components/App/AppHeader/AppHeaderBaseMenu.vue'
import AppHeaderNotifs from '@/components/App/AppHeader/AppHeaderNotifs.vue'

export default {
  name: 'AppHeaderPrivate',

  components: { BaseLogo, AppHeaderBaseMenu, AppHeaderNotifs },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', [
      'isHub',
      'isMember',
      'isAccountIncomplete',
      'isUserRole'
    ]),
    ...mapState('tenants', ['tenants']),
    ...mapState('admin/tenants', {
      adminTenants: 'tenants'
    }),

    isTenant() {
      return Boolean(this.tenants.selected || this.$route.params.tenantMarker)
    },

    isAccountComplete() {
      return !this.isAccountIncomplete
    },

    tenant() {
      if (this.$isOn.adminPage) {
        const pharmYardTenant = { name: 'PharmYard', marker: '' }

        return this.adminTenants.selected?.length > 1
          ? pharmYardTenant
          : this.adminTenants.selected[0]
      }

      return this.tenants?.selected

      // if (this.$isOn.principalPage)
      //   return this.tenants?.selected || pharmYardTenant

      // return this.adminTenants.selected?.length > 1
      //   ? pharmYardTenant
      //   : this.adminTenants.selected[0]
    },

    menu() {
      const isTenantChooserAvailable =
        this.isUserRole.anyAdmin && this.user.tenants?.length > 1

      const memberList = [
        {
          icon: 'mdi-account-circle-outline',
          label: MEMBER_TABS.labels.PERSON_INFO,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.PERSON_INFO }
          }
        },
        {
          icon: 'mdi-heart-plus-outline',
          label: MEMBER_TABS.labels.HEALTHCARE,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.HEALTHCARE }
          }
        },
        {
          icon: 'mdi-math-compass',
          label: MEMBER_TABS.labels.EXPERTISE,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.EXPERTISE }
          }
        },
        {
          icon: 'mdi-school-outline',
          label: MEMBER_TABS.labels.ASSESSMENTS,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.ASSESSMENTS }
          }
        },
        {
          icon: 'mdi-calendar-month-outline',
          label: MEMBER_TABS.labels.AVAILABILITY,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.AVAILABILITY }
          }
        },
        {
          icon: 'mdi-file-account',
          label: MEMBER_TABS.labels.PORTFOLIO_FILES,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.PORTFOLIO_FILES }
          }
        },
        {
          icon: 'mdi-file-link-outline',
          label: MEMBER_TABS.labels.PORTFOLIO_LINKS,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.PORTFOLIO_LINKS }
          }
        },
        {
          icon: 'mdi-cash',
          label: MEMBER_TABS.labels.RATES,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.RATES }
          }
        },
        {
          icon: 'mdi-script-outline',
          label: MEMBER_TABS.labels.CONTRACTS,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.CONTRACTS }
          }
        },
        // {
        //   icon: 'mdi-certificate-outline',
        //   label: MEMBER_TABS.labels.CREDENTIALS,
        //   to: {
        //     name: 't.member.profile.tab',
        //     params: { tabCode: MEMBER_TABS.codes.CREDENTIALS }
        //   }
        // },
        this.user?.canUploadInvoices && {
          icon: 'mdi-receipt',
          label: MEMBER_TABS.labels.INVOICES,
          to: {
            name: 't.member.profile.tab',
            params: { tabCode: MEMBER_TABS.codes.INVOICES }
          }
        }
      ]

      const hubList = [
        {
          icon: 'mdi-office-building-outline',
          label: HUB_TABS.labels.GENERAL_INFO,
          to: {
            name: 't.hub.profile.tab',
            params: { tabCode: HUB_TABS.codes.GENERAL_INFO }
          }
        },
        {
          icon: 'mdi-map-marker-multiple-outline',
          label: HUB_TABS.labels.LOCATIONS,
          to: {
            name: 't.hub.profile.tab',
            params: { tabCode: HUB_TABS.codes.LOCATIONS }
          }
        },
        {
          icon: 'mdi-math-compass',
          label: HUB_TABS.labels.EXPERTISE,
          to: {
            name: 't.hub.profile.tab',
            params: { tabCode: HUB_TABS.codes.EXPERTISE }
          }
        },
        {
          icon: 'mdi-currency-usd',
          label: HUB_TABS.labels.RATES,
          to: {
            name: 't.hub.profile.tab',
            params: { tabCode: HUB_TABS.codes.RATES }
          }
        },
        {
          icon: 'mdi-clipboard-outline',
          label: HUB_TABS.labels.PORTFOLIO,
          to: {
            name: 't.hub.profile.tab',
            params: { tabCode: HUB_TABS.codes.PORTFOLIO }
          }
        },
        {
          icon: 'mdi-script-outline',
          label: HUB_TABS.labels.CONTRACTS,
          to: {
            name: 't.hub.profile.tab',
            params: { tabCode: HUB_TABS.codes.CONTRACTS }
          }
        }
      ]

      return {
        adminList: [
          {
            icon: 'mdi-view-dashboard-outline',
            label: 'Dashboard',
            to: { name: 'admin.home' }
          },
          {
            icon: 'mdi-text-search',
            label: 'Member Finder',
            list: [
              {
                label: 'Public',
                to: {
                  name: 'admin.member-finder.type',
                  params: { type: 'public' }
                }
              },
              {
                label: 'Private',
                to: {
                  name: 'admin.member-finder.type',
                  params: { type: 'private' }
                }
              }
            ]
          },
          {
            icon: 'mdi-account-group',
            label: 'People',
            list: [
              {
                label: 'Featured Creatives',
                to: { name: 'admin.featured-creatives' }
              },
              { label: 'Hubs', to: { name: 'admin.new-hubs' } },
              { label: 'Individuals', to: { name: 'admin.people' } },
              { label: 'Teams', to: { name: 'admin.teams' } },
              {
                label: 'Me',
                to: { name: 'admin.people.id', params: { id: this.user.id } }
              }
            ]
          },

          {
            icon: 'mdi-format-list-bulleted-square',
            label: 'Content',
            list: [
              { label: 'Announcements', to: { name: 'admin.announcements' } },
              { label: 'Categories', to: { name: 'admin.categories' } },
              { label: 'FAQs', to: { name: 'admin.faqs' } },
              {
                label: 'Showcases',
                to: { name: 'admin.showcases' }
              },
              { label: 'Widgets', to: { name: 'admin.pages' } }
            ].filter(Boolean)
          },
          {
            icon: 'mdi-briefcase-outline',
            label: 'Work',
            list: [
              { label: 'Accounting', to: { name: 'admin.accounting' } },
              {
                label: 'Assessments',
                to: { name: 'admin.assessment.definitions' }
              },
              { label: 'Jobs', to: { name: 'admin.job-manager' } },
              {
                label: 'Submissions',
                to: { name: 'admin.submission-reviewer' }
              }
            ]
          },
          {
            icon: 'mdi-tools',
            label: 'System',
            list: [
              { label: 'Attributes', to: { name: 'admin.attributes' } },
              {
                label: 'Professions',
                to: { name: 'admin.professions' }
              },
              {
                label: 'Medical Specialties',
                to: { name: 'admin.medical-specialties' }
              },
              { label: 'Focus Areas', to: { name: 'admin.focus-areas' } },
              { label: 'Work Functions', to: { name: 'admin.work-functions' } },
              { label: 'Companies', to: { name: 'admin.tenants' } },
              { label: 'Contracts', to: { name: 'admin.contracts-manager' } },
              {
                label: 'Forms',
                to: { name: 'admin.dynamic-forms-manager' }
              },
              { label: 'Searches', to: { name: 'admin.saved-searches' } }
            ]
          },

          { isDivider: true },
          {
            icon: 'mdi-logout',
            label: 'Logout',
            color: 'error',
            handler: this.logout
          }
        ],
        profileList: (this.isMember ? memberList : hubList).filter(Boolean),
        commonPrincipalList: [
          ...(this.isAccountComplete
            ? [
                { icon: 'mdi-home', label: 'Home', to: { name: 't.home' } },
                {
                  icon: 'mdi-account',
                  label: 'My Profile',
                  to: this.isHub
                    ? { name: 't.hub.profile' }
                    : { name: 't.member.profile' }
                },
                this.$isTenant.crowdPharm && {
                  // this.$defineIsTenantByUser(this.user).crowdPharm && {
                  icon: 'mdi-image-multiple-outline',
                  label: 'Showcase',
                  to: { name: 't.showcase' }
                },
                {
                  icon: 'mdi-script-text-outline',
                  label: 'AI Guidelines',
                  to: { name: 't.ai-guidelines' }
                },
                {
                  icon: 'mdi-frequently-asked-questions',
                  label: 'FAQs',
                  to: { name: 't.faqs' }
                },
                {
                  icon: 'mdi-message-cog-outline',
                  label: 'Tech Support',
                  to: { name: 't.tech-support' }
                },
                {
                  icon: 'mdi-face-agent',
                  label: 'Talk To Us',
                  to: { name: 't.talk' }
                },

                isTenantChooserAvailable && {
                  icon: 'mdi-shield-key-outline',
                  label: 'Change Company',
                  to: {
                    name: 'select-tenant',
                    query: (() => {
                      const toName = this.$isOn.principalPage
                        ? this.$route.name
                        : 't.home'

                      return {
                        toName,
                        ...this.$route.params,
                        tenantMarker: undefined
                      }
                    })()
                  }
                }
              ].filter(Boolean)
            : []),
          { isDivider: true },
          {
            icon: 'mdi-logout',
            label: 'Logout',
            color: 'error',
            handler: this.logout
          }
        ].filter(Boolean)
      }
    }
  },

  methods: {
    ...mapActions('auth', {
      logout: 'LOGOUT'
    })
  }
}
</script>
