<template>
  <v-list class="!bg-transparent" three-line>
    <v-list-item v-if="isLoading" :style="`height: ${itemHeight}px`">
      <v-list-item-avatar class="mr-4">
        <v-skeleton-loader type="avatar" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-skeleton-loader type="sentences" />
      </v-list-item-content>
    </v-list-item>

    <template v-else>
      <v-list-item v-for="item of list" :key="`link-${item.id}`">
        <v-list-item-avatar class="mr-4">
          <v-avatar rounded color="bg-gray-300">
            <v-icon class="!text-white">mdi-link-variant</v-icon>
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="flex justify-between items-center">
            <a
              class="leaders after:bottom-[2px] text-blue-500 overflow-hidden truncate"
              :href="item.value"
              target="_blank"
            >
              <span class="underline"> {{ defineLinkLabel(item.value) }}</span>
              <v-icon x-small class="ml-1 !text-blue-500">
                mdi-open-in-new
              </v-icon>
            </a>

            <MemberProfileLinksListItemMenu
              @action-update="() => handleUpdate(item)"
              @action-delete="() => handleDelete(item)"
            />
          </v-list-item-title>

          <v-tooltip
            top
            :max-width="512"
            :disabled="!item.description || item.description?.length < 100"
          >
            <template #activator="{ on, attrs }">
              <v-list-item-subtitle
                class="min-h-[34px]"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.userUriTypeName }}. {{ item.description }}
              </v-list-item-subtitle>
            </template>

            <span>{{ item.description }}</span>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import MemberProfileLinksListItemMenu from '@/components/Tenant/Member/MemberProfile/MemberProfileLinks/MemberProfileLinksListItemMenu.vue'

export default {
  name: 'TenantMemberProfileLinksList',

  components: { MemberProfileLinksListItemMenu },

  props: {
    list: { type: Array, default: () => [] },

    itemHeight: { type: Number, default: 72 },

    isLoading: { type: Boolean, default: false }
  },

  methods: {
    handleUpdate(item) {
      this.$emit('action-update', item)
    },
    handleDelete(item) {
      this.$emit('action-delete', item)
    },

    defineLinkLabel(link) {
      return link.replace(/^https?:\/\//, '')
    }
  }
}
</script>
