import Vue from 'vue'
import router from '@/router'
;(async () => {
  try {
    const Sentry = await import('@sentry/vue')

    Sentry.init({
      Vue,
      dsn: 'https://624e1ad56d1c9cf68efdd348916bfe81@o4506424764989440.ingest.us.sentry.io/4506428845391872',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration()
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        import.meta.env.PROD
          ? 'https://www.pharmyard.com/'
          : 'https://dev.pharmyard.com/'
      ],
      environment: import.meta.env.MODE,
      // Session Replay
      replaysSessionSampleRate: import.meta.env.PROD ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      enabled: window.location.protocol === 'https:'
    })
  } catch (error) {
    console.error('Failed to load Sentry:', error)
  }
})()
