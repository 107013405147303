<template>
  <div v-loader="isLoading">
    <div v-if="isEmpty" class="px-4 py-2 text-gray-500">
      <span>(The list is empty.)</span>
    </div>

    <div v-else class="flex flex-col overflow-auto">
      <!-- <BudgetContentListHeader /> -->

      <div v-for="(item, index) of list" :key="item.id">
        <BudgetContentListItem
          :item="item"
          :index="index"
          @refresh="handleRefresh"
          @open-info-dialog="tabCode => handleOpenInfoDialog({ item })"
        />
      </div>

      <BudgetContentListSummary :budget="budget.content" :payouts="list" />
    </div>

    <BudgetContentListItemDialog
      v-if="infoDialog.isVisible"
      :user-id="infoDialog.userId"
      @close="handleCloseInfoDialog"
      @refresh="handleRefresh"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import BudgetContentListItem from '@/components/Admin/JobManager/Budget/BudgetContentListItem.vue'
import BudgetContentListSummary from '@/components/Admin/JobManager/Budget/BudgetContentListSummary.vue'
import BudgetContentListItemDialog from '@/components/Admin/JobManager/Budget/BudgetContentListItemDialog.vue'

export default {
  name: 'AdminTeamsCatalogContentList',

  components: {
    BudgetContentListItem,
    BudgetContentListSummary,
    BudgetContentListItemDialog
  },

  data: () => ({
    infoDialog: {
      isVisible: false,
      userId: null
    }
  }),

  computed: {
    ...mapState('admin/jobManager/budget', ['budget']),

    list() {
      return this.budget.rows.list
    },

    isLoading() {
      return this.budget.isLoading
    },

    isList() {
      return Boolean(this.list.length)
    },

    isEmpty() {
      return !this.isLoading && !this.isList
    }
  },

  methods: {
    ...mapActions('admin/jobManager/budget', {
      fetchAll: 'FETCH_ALL'
    }),

    handleRefresh() {
      this.fetchAll()
    },

    handleOpenInfoDialog({ item }) {
      this.infoDialog.userId = item.payeeUser?.id
      this.infoDialog.isVisible = true
    },
    handleCloseInfoDialog() {
      this.infoDialog.isVisible = false
    }
  }
}
</script>
