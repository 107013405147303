<template>
  <div
    class="w-full bg-gray-900 border-t"
    :style="`padding-bottom: ${cookieBannerHeight}px`"
  >
    <div
      class="p-8 gap-8 grid md:p-16 grid-cols-2"
      :class="{
        'md:grid-cols-4': isAccountComplete || !isAuthorized,
        'md:grid-cols-3': isAccountIncomplete && isAuthorized
      }"
    >
      <div v-if="tenant" class="gap-4 flex flex-col text-sm sm:text-base">
        <img
          class="w-40"
          :src="tenant?.files?.footerLogo?.path"
          alt="Footer Logo"
        />
      </div>

      <div
        v-for="(block, index) of blocks"
        :key="`block-${index}`"
        class="gap-4 flex flex-col text-sm sm:text-base"
      >
        <strong class="text-white">{{ block.title }}</strong>

        <div
          v-for="(child, childIndex) of block.children"
          :key="`child-${childIndex}`"
          class="group text-gray-400 cursor-pointer"
          :class="child.class"
          @click="child.handler"
        >
          <span class="group-hover:underline">{{ child.label }}</span>
        </div>
      </div>
    </div>

    <div class="px-8 md:px-16">
      <v-divider dark />
    </div>

    <div
      class="w-full gap-8 p-8 flex flex-col-reverse items-center justify-between md:px-16 sm:flex-row"
    >
      <small class="text-gray-400">
        &copy; {{ currentYear }} PharmYard. All rights reserved.
      </small>

      <div class="gap-8 flex flex-initial">
        <a
          v-for="item of social"
          :key="item.label"
          class="h-8 w-8 flex justify-center items-center !text-gray-400 hover:!text-white cursor-pointer"
          :href="item.link"
          target="_blank"
        >
          <svg class="h-6 w-6 flex" fill="currentColor" viewBox="0 0 24 24">
            <title>{{ item.label }}</title>
            <path :d="item.svg" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber'
import { mapState, mapGetters } from 'vuex'

import * as services from '@/services'

const generateGoogleMapsLink = address => {
  const addressParts = [
    address.streetAddress1,
    address.streetAddress2,
    address.cityName,
    address.stateOrProvince,
    address.country,
    address.postalCode
  ].filter(Boolean)
  const formattedAddress = addressParts.join(', ').replace(/ /g, '+')
  return `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`
}

export default {
  name: 'AppFooterPrincipal',

  data: () => ({
    cookieBannerHeight: 0,

    addresses: {
      list: [],
      isLoading: false
    }
  }),

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAccountIncomplete', 'isHub']),
    ...mapGetters('tenants', ['getTenantByMarker']),
    ...mapGetters('auth', ['isAuthorized']),

    tenant() {
      const tenantMarker = this.$route.params.tenantMarker
      const tenantFromPath = this.getTenantByMarker(tenantMarker)

      const tenants = this.$store.state.tenants.tenants
      const principalTenant = tenants.selected

      const adminTenants = this.$store.state.admin.tenants.tenants
      const adminTenant =
        adminTenants.selected.length === 1 ? adminTenants.selected[0] : null

      return tenantFromPath || principalTenant || adminTenant
    },

    isAccountComplete() {
      return !this.isAccountIncomplete
    },

    currentYear() {
      return new Date().getFullYear()
    },

    blocks() {
      return [
        this.isAccountComplete && {
          title: 'Explore',
          children: [
            {
              label: 'Home',
              handler: () => this.$router.push({ name: 't.home' })
            },
            {
              label: 'Profile',
              handler: () =>
                this.$router.push(
                  this.isHub
                    ? { name: 't.hub.profile' }
                    : { name: 't.member.profile' }
                )
            },
            this.isMember &
              {
                label: 'Projects',
                handler: () => this.$router.push({ name: 't.member.projects' })
              },
            {
              label: 'FAQs',
              handler: () => this.$router.push({ name: 't.faqs' })
            },
            this.$isTenant.crowdPharm && {
              label: 'Showcase',
              handler: () => this.$router.push({ name: 't.showcase' })
            },
            {
              label: 'Help',
              handler: () => this.$router.push({ name: 't.talk' })
            }
          ].filter(Boolean)
        },
        this.addresses.list?.length && {
          title: 'Visit',
          children: [
            ...this.addresses.list.map(item => {
              return {
                label: item.name,
                isExternal: true,
                handler: () => {
                  const link = generateGoogleMapsLink(item)
                  window.open(link, '_blank')
                }
              }
            })
            // {
            //   label: 'Saratoga Springs, NY',
            //   isExternal: true,
            //   handler: () =>
            //     window.open(
            //       'https://maps.google.com/?q=153 Regent st, saratoga springs, NY',
            //       '_blank'
            //     )
            // },
            // {
            //   label: 'Kansas City, MO',
            //   isExternal: true,
            //   handler: () =>
            //     window.open(
            //       'https://maps.google.com/?q=4600 Madison Avenue, Kansas City, MO 64112',
            //       '_blank'
            //     )
            // },
            // {
            //   label: 'Tucson, AZ',
            //   isExternal: true,
            //   handler: () =>
            //     window.open(
            //       'https://maps.google.com/?q=724 N. Campbell Avenue Tucson, AZ 85719',
            //       '_blank'
            //     )
            // },
            // {
            //   label: 'San Diego, CA',
            //   isExternal: true,
            //   handler: () =>
            //     window.open(
            //       'https://maps.google.com/?q=4660 La Jolla Village Dr, San Diego, CA 92122',
            //       '_blank'
            //     )
            // },
            // {
            //   label: 'Singapore',
            //   isExternal: true,
            //   handler: () =>
            //     window.open('https://goo.gl/maps/kwXCxG6JKv3gBPqd9', '_blank')
            // }
          ]
        },
        {
          title: 'Contact',
          children: [
            {
              label: PhoneNumber.parsePhoneNumber(
                this.tenant?.phone || '+19137774276'
              )?.number?.international,
              isExternal: false,
              handler: () =>
                window.open(
                  `tel:${this.tenant?.phone || '+19137774276'}`,
                  '_blank'
                )
            },
            {
              label: this.tenant?.email || 'info@crowdpharm.com',
              class: 'break-all',
              isExternal: false,
              handler: () =>
                window.open(
                  `mailto:${this.tenant?.email || 'info@crowdpharm.com'}`,
                  '_blank'
                )
            }
          ]
        },
        {
          title: 'Legal',
          children: [
            {
              label: 'Privacy and cookies',
              handler: () => this.$router.push({ name: 't.privacy' })
            }
          ]
        }
      ].filter(Boolean)
    },

    social() {
      return [
        {
          label: 'Facebook',
          svg: 'M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z',
          link:
            this.tenant?.facebookUrl || 'https://www.facebook.com/crowdpharm/'
        },
        {
          label: 'Instagram',
          svg: 'M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z',
          link:
            this.tenant?.instagramUrl || 'https://www.instagram.com/crowdpharm/'
        },
        {
          label: 'LinkedIn',
          svg: 'M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z',
          link:
            this.tenant?.linkedInUrl ||
            'https://www.linkedin.com/company/crowdpharm/'
        }
      ]
    }
  },

  watch: {
    tenant: {
      immediate: true,
      handler: 'fetchAllAddresses'
    }
  },

  mounted() {
    this.watchForCookieBannerHeight()
  },

  methods: {
    watchForCookieBannerHeight() {
      const targetElement = document.querySelector('#cookie-banner')

      if (!targetElement) return

      const resizeObserver = new ResizeObserver(entries => {
        const entry = entries[0]

        this.cookieBannerHeight = entry?.target.clientHeight
      })

      resizeObserver.observe(targetElement)
    },

    async fetchAllAddresses() {
      if (!this.tenant?.id) return

      this.addresses.isLoading = true
      const { list } = await services.tenantAddresses.fetchAllByTenantId({
        id: this.tenant.id
      })
      this.addresses.isLoading = false
      this.addresses.list = list
    }
  }
}
</script>
