<template>
  <div class="gap-4 flex flex-col">
    <!-- <v-card key="content-stats">
      <v-card-text class="gap-4 flex flex-col">
        <BudgetContentStats />
      </v-card-text>
    </v-card> -->

    <v-card class="flex flex-col">
      <BudgetContentHeader
        class="w-full flex"
        :is-budget="Boolean(budget.content)"
      />

      <v-divider />

      <div>
        <div
          v-if="budget.isLoading"
          class="h-[127px] flex items-center justify-center"
        >
          <v-progress-circular indeterminate size="32" color="grey lighten-2" />
        </div>

        <template v-else>
          <BudgetContentList v-if="budget.content" />
          <BudgetContentEmpty v-else />
        </template>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// import BudgetContentStats from '@/components/Admin/JobManager/Budget/BudgetContentStats.vue'
import BudgetContentHeader from '@/components/Admin/JobManager/Budget/BudgetContentHeader.vue'
import BudgetContentList from '@/components/Admin/JobManager/Budget/BudgetContentList.vue'
import BudgetContentEmpty from '@/components/Admin/JobManager/Budget/BudgetContentEmpty.vue'

export default {
  name: 'AdminJobManagerBudgetContent',

  components: {
    // BudgetContentStats,
    BudgetContentHeader,
    BudgetContentList,
    BudgetContentEmpty
  },

  computed: {
    ...mapState('admin/jobManager/budget', ['budget'])
  }
}
</script>
