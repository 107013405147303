<template>
  <div>
    <v-divider />

    <div class="w-full h-full gap-4 px-4 py-2 flex items-center">
      <div class="w-full gap-4 flex items-center justify-end">
        <div class="w-32 flex flex-col flex-initial items-center text-center">
          <span class="font-semibold">{{
            formatCurrencyWithSpaces(totalCost)
          }}</span>
          <span class="text-xs text-gray-500">Total paid out</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrencyWithSpaces } from '@/utils/base'

export default {
  name: 'TenantPrincipalPayoutsListSummary',

  props: {
    payouts: { type: Array, required: true }
  },

  computed: {
    totalCost() {
      return this.payouts.reduce(
        (acc, item) => acc + item.totalPaymentAmount,
        0
      )
    }
  },

  created() {
    this.formatCurrencyWithSpaces = formatCurrencyWithSpaces
  }
}
</script>
