export default {
  data: () => ({
    observer: null
  }),

  mounted() {
    this.setupObserver()
  },

  beforeDestroy() {
    this.disconnectObserver()
  },

  methods: {
    setupObserver() {
      if (!this.$refs.input) return

      this.observer = new MutationObserver(mutations => {
        for (const mutation of mutations) {
          const value = mutation.target.getAttribute(mutation.attributeName)
          this.$nextTick(() => this.handleInputClassChange(value))
        }
      })

      this.observer.observe(this.$refs.input.$el, {
        attributes: true,
        attributeFilter: ['class']
      })
    },

    disconnectObserver() {
      this.observer?.disconnect()
    },

    handleInputClassChange(classAttrValue) {
      const classList = classAttrValue.split(' ')
      this.input.isError = classList.includes('error--text')
    }
  }
}
