import http from 'axios'

import * as notify from '@/utils/notify'

import budget from '@/store/modules/admin/job-manager/budget'

export default {
  namespaced: true,

  modules: { budget },

  state: {
    jobManager: {
      assignmentTypes: [],
      assignmentStatuses: [],
      clients: [],

      isLoading: false,

      templatesSuggestions: []
    }
  },

  actions: {
    FETCH_JOB_OPTIONS({ commit }) {
      const promises = []

      commit('SET_IS_LOADING', true)

      promises.push(
        http
          .get('/api/assignment/get-all-types')
          .then(response => {
            if (response.error) {
              notify.error({ text: response.error })
              return
            }

            commit('SET_ASSIGNMENT_TYPES', response.content)
          })
          .catch(error => {
            notify.error({ text: error })
          })
          .finally(() => {})
      )

      promises.push(
        http
          .get('/api/assignment/get-all-statuses')
          .then(response => {
            if (response.error) {
              notify.error({ text: response.error })
              return
            }

            commit('SET_ASSIGNMENT_STATUSES', response.content)
          })
          .catch(error => {
            notify.error({ text: error })
          })
          .finally(() => {})
      )

      promises.push(
        http
          .get('/api/clients/get-all')
          .then(response => {
            if (response.error) {
              notify.error({ text: response.error })
              return
            }

            commit('SET_CLIENTS', response.content)
          })
          .catch(error => {
            notify.error({ text: error })
          })
          .finally(() => {})
      )

      return Promise.all(promises).finally(() => {
        commit('SET_IS_LOADING', false)
      })
    }
  },

  mutations: {
    SET_ASSIGNMENT_TYPES(state, value) {
      state.jobManager.assignmentTypes = value
    },
    SET_ASSIGNMENT_STATUSES(state, value) {
      state.jobManager.assignmentStatuses = value
    },
    SET_CLIENTS(state, value) {
      state.jobManager.clients = value
    },
    SET_IS_LOADING(state, isLoading) {
      state.jobManager.isLoading = isLoading
    },
    SET_TEMPLATES_SUGGESTIONS(state, templatesSuggestions) {
      console.log('templatesSuggestions', templatesSuggestions)
      state.jobManager.templatesSuggestions = templatesSuggestions
    }
  }
}
