<template>
  <div v-loader="isLoading">
    <div v-if="isEmpty" class="px-4 py-2 text-gray-500">
      <span>(The list is empty.)</span>
    </div>

    <div v-else class="flex flex-col overflow-auto">
      <PrincipalPayoutsListItem
        v-for="(item, index) of list"
        :key="item.id"
        :item="item"
        :index="index"
      />

      <PrincipalPayoutsListSummary :payouts="list" />
    </div>
  </div>
</template>

<script>
import PrincipalPayoutsListItem from '@/components/Tenant/Principal/PrincipalPayouts/PrincipalPayoutsListItem.vue'
import PrincipalPayoutsListSummary from '@/components/Tenant/Principal/PrincipalPayouts/PrincipalPayoutsListSummary.vue'

export default {
  name: 'AdminTeamsCatalogContentList',

  components: {
    PrincipalPayoutsListItem,
    PrincipalPayoutsListSummary
  },

  props: {
    budget: { type: Object, required: true },
    isLoading: { type: Boolean, default: false }
  },

  computed: {
    list() {
      return this.budget.assignmentPayouts
    },

    isList() {
      return Boolean(this.list.length)
    },

    isEmpty() {
      return !this.isLoading && !this.isList
    }
  }
}
</script>
