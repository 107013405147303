<template>
  <div class="h-full w-full p-0">
    <slot />

    <AdminTenantFilter v-if="isTenantFilter" id="admin-tenant-filter" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AdminTenantFilter from '@/components/Admin/AdminTenantFilter.vue'

export default {
  name: 'AppContent',

  components: { AdminTenantFilter },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isUserRole']),

    isTenantFilter() {
      const defineIsAllowedByUser = () => {
        const isAdminPage = this.$route.name?.includes('admin')

        return (
          this.isUserRole.multiTenantAdmin &&
          isAdminPage &&
          this.user.tenants.length > 1
        )
      }

      const defineIsAllowedByPage = () => {
        if (this.$route.name === 'admin.work-functions') return false
        if (this.$route.name === 'admin.focus-areas') return false
        if (this.$route.name === 'admin.saved-searches') return false
        if (this.$route.name === 'admin.old-saved-searches') return false
        if (this.$route.name === 'admin.tenants') return false
        if (this.$route.name === 'admin.job-submissions-viewer') return false
        if (
          this.$route.name ===
          'admin.assessment.definitions.id.tasks.id.attempts.id'
        )
          return false

        return true
      }

      const isAllowedByUser = defineIsAllowedByUser()
      const isAllowedByPage = defineIsAllowedByPage()

      return isAllowedByUser && isAllowedByPage
    }
  }
}
</script>
