<template>
  <div>
    <v-divider v-if="index" />

    <div class="w-full h-full gap-4 px-4 py-2 flex items-center">
      <div class="w-full gap-4 flex items-center">
        <div class="w-10 flex flex-initial items-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn icon fab small @click="$emit('open-info-dialog')">
                  <v-icon color="primary"> mdi-account-search-outline </v-icon>
                </v-btn>
              </div>
            </template>

            <span>Preview</span>
          </v-tooltip>
        </div>

        <span class="text-gray-400 break-keep">{{ index + 1 }}. </span>

        <div class="w-px min-w-[15rem] flex flex-col overflow-hidden">
          <div>
            <router-link
              class="gap-1 inline-flex group flex flex-initial"
              target="_blank"
              :to="entity.to"
            >
              <div class="inline-flex flex-initial overflow-hidden">
                <span class="group-hover:underline font-semibold truncate">
                  {{ entity.name }}
                </span>
              </div>

              <v-icon small color="primary"> mdi-open-in-new </v-icon>
            </router-link>
          </div>

          <div class="truncate text-primary">
            <a
              :href="`mailto:${entity.email}`"
              target="_blank"
              class="font-light text-sm"
            >
              <span class="underline">{{ entity.email }}</span>
            </a>
          </div>

          <!-- <div>
            <a
              :href="`tel:${entity.primaryPhone}`"
              target="_blank"
              class="font-light text-sm"
            >
              <span class="underline">{{ entity.primaryPhone }}</span>
            </a>
          </div> -->
        </div>

        <div class="w-[10rem] flex flex-col flex-shrink-0">
          <span>{{ item.workFunction.name }}</span>
          <span class="text-xs text-gray-500">Function</span>
        </div>

        <div class="w-28 flex flex-col flex-shrink-0 items-center text-center">
          <div class="gap-1 flex items-center">
            <v-icon small>mdi-calendar-account-outline</v-icon>

            {{ $formatDate(item.updateAt || item.createdAt) || '–' }}
          </div>

          <span class="text-xs text-gray-500">Updated at</span>
        </div>

        <div class="w-20 flex flex-col flex-shrink-0 items-center text-center">
          <span>{{
            formatCurrencyWithSpaces(item.assignedPaymentAmount)
          }}</span>
          <span class="text-xs text-gray-500">Assigned</span>
        </div>
        <div class="w-20 flex flex-col flex-shrink-0 items-center text-center">
          <span>{{
            formatCurrencyWithSpaces(item.revisionsPaymentAmount)
          }}</span>
          <span class="text-xs text-gray-500">Revisions</span>
        </div>
        <div class="w-20 flex flex-col flex-shrink-0 items-center text-center">
          <span>{{
            formatCurrencyWithSpaces(item.finalistPaymentAmount)
          }}</span>
          <span class="text-xs text-gray-500">Finalist</span>
        </div>
        <div class="w-20 flex flex-col flex-shrink-0 items-center text-center">
          <span>{{ formatCurrencyWithSpaces(item.winnerPaymentAmount) }}</span>
          <span class="text-xs text-gray-500">Winner</span>
        </div>
        <div class="pr-[4.8rem]">
          <div
            class="w-20 flex flex-col flex-shrink-0 items-center text-center"
          >
            <span class="font-semibold">{{
              formatCurrencyWithSpaces(item.totalPaymentAmount)
            }}</span>
            <span class="text-xs text-gray-500">Total</span>
          </div>
        </div>

        <div>
          <BaseMenu
            :is-visible="menu.isVisible"
            :header-title="`Payout for ${entity.email}`"
            :buttons="menuButtons"
            @close="handleCloseMenu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import * as services from '@/services'
import * as notify from '@/utils/notify'
import * as confirm from '@/utils/confirm'
import { formatCurrencyWithSpaces } from '@/utils/base'

import BaseMenu from '@/components/Admin/Base/BaseMenu.vue'

export default {
  name: 'AdminJobManagerBudgetContentListItem',

  components: { BaseMenu },

  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true }
  },

  data: () => ({
    menu: {
      isVisible: false
    }
  }),

  computed: {
    entity() {
      const user = this.item.payeeUser || {}

      return {
        name: user.name,
        email: user.email,
        // primaryPhone: user.primaryPhone,
        to: { name: 'admin.people.id', params: { id: this.item.payeeUser?.id } }
      }
    },

    menuButtons() {
      return [
        {
          icon: 'mdi-pencil-outline',
          label: 'Edit',
          color: 'secondary',
          handler: this.handleEdit
        },
        {
          icon: 'mdi-trash-can-outline',
          label: 'Delete',
          color: 'error',
          handler: this.handleDelete
        }
      ].filter(Boolean)
    }
  },

  created() {
    this.formatCurrencyWithSpaces = formatCurrencyWithSpaces
  },

  methods: {
    ...mapMutations('admin/jobManager/budget', {
      updateUi: 'UPDATE_UI'
    }),

    handleCloseMenu() {
      this.menu.isVisible = false
    },

    handleEdit() {
      this.updateUi({
        createPayoutDialog: { isVisible: true, payout: this.item }
      })
    },

    async handleDelete() {
      const text = `Are you sure you want to delete the payout for ${this.entity.name}?`
      const { isConfirmed } = await confirm.warning({ text })

      if (!isConfirmed) return

      const { isSuccess } = await services.assignmentPayouts.deleteOne({
        id: this.item.id
      })

      if (!isSuccess) return

      notify.success({ title: 'Payout deleted' })
      this.handleRefresh()
    },

    handleRefresh() {
      this.$emit('refresh')
    }
  }
}
</script>
