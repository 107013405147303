import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from '@/store/modules/auth'
import mfa from '@/store/modules/mfa'
import user from '@/store/modules/user'
import hub from '@/store/modules/hub'
import admin from '@/store/modules/admin'
import uris from '@/store/modules/uris'
import tenants from '@/store/modules/tenants'
import contracts from '@/store/modules/contracts'
import ui from '@/store/modules/ui'

import rates from '@/store/modules/rates'
import clients from '@/store/modules/clients'

import memberProject from '@/store/modules/member-project'
import memberFinder from '@/store/modules/member-finder'

import attributes from '@/store/modules/attributes'

import healthcareExperience from '@/store/modules/healthcare-experience'
import principal from '@/store/modules/principal'
import assessment from '@/store/modules/assessment'
import memberNotifications from '@/store/modules/member-notifications'
// import showcaseFiles from '@/store/modules/showcase-files'

export default new Vuex.Store({
  modules: {
    auth,
    mfa,
    user,
    hub,
    admin,
    uris,
    tenants,
    contracts,
    ui,

    rates,
    clients,

    memberProject,
    memberFinder,

    attributes,

    healthcareExperience,
    principal,
    assessment,
    memberNotifications

    // showcaseFiles
  }
})
