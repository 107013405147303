<template>
  <MemberProfileLinks
    :header-title="headerTitle"
    header-subtitle="Add links to your portfolio, resume/CV, social media accounts, and anything else we should know about"
    :principal="user"
    @updated="$emit('updated')"
  />
</template>

<script>
import MemberProfileLinks from '@/components/Tenant/Member/MemberProfile/MemberProfileLinks/MemberProfileLinks.vue'

export default {
  name: 'TenantMemberProfileTabsLinks',

  components: { MemberProfileLinks },

  props: {
    user: { type: Object, required: true },
    headerTitle: { type: String, default: 'Links' }
  }
}
</script>
