<template>
  <div class="flex flex-col flex-initial">
    <div v-for="assignment of assignments.list" :key="assignment.id">
      <template v-if="isMultipleAssignments">
        <div class="py-2 px-4 bg-gray-100">
          <router-link
            class="hover:underline"
            target="_blank"
            :to="{
              name: 't.member.projects.id',
              params: { assignmentId: assignment.id }
            }"
          >
            {{ assignment.title }}

            <v-icon x-small color="primary"> mdi-open-in-new </v-icon>
          </router-link>
        </div>

        <v-divider />
      </template>

      <PrincipalPayoutsList :budget="assignment.budgets[0]" />
    </div>
  </div>
</template>

<script>
import * as services from '@/services'

import PrincipalPayoutsList from '@/components/Tenant/Principal/PrincipalPayouts/PrincipalPayoutsList.vue'

export default {
  name: 'TenantPrincipalPayouts',

  components: { PrincipalPayoutsList },

  props: {
    principalId: { type: Number, required: true },
    assignmentId: { type: Number, default: null }
  },

  data: () => ({
    assignments: {
      list: [],
      isLoading: false
    }
  }),

  computed: {
    isMultipleAssignments() {
      return this.assignments.list.length > 1
    }
  },

  created() {
    this.handleRefresh()
  },

  methods: {
    async handleRefresh() {
      this.assignments.isLoading = true

      const { list } =
        await services.assignments.fetchAllWithBudgetsAndPayoutsByUserId({
          userId: this.principalId
        })

      this.assignments.list = this.assignmentId
        ? list.filter(assignment => assignment.id === this.assignmentId)
        : list
      this.assignments.isLoading = false
    }
  }
}
</script>
