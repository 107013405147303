<template>
  <div
    class="w-full py-4 px-4 gap-2 flex flex-wrap items-center justify-between sm:py-2"
  >
    <div class="flex flex-1 flex-col">
      <b>Budget</b>
      <span class="text-gray-500">Manage the assignment payouts</span>
    </div>

    <div v-if="isBudget" class="gap-4 flex flex-initial">
      <v-btn small color="secondary" @click="handleOpenEditBudgetDialog">
        <v-icon left>mdi-pencil</v-icon>
        <span>Edit budget</span>
      </v-btn>

      <v-btn small color="primary" @click="handleOpenCreatePayoutDialog">
        <v-icon left>mdi-plus</v-icon>
        <span>Add payout</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'AdminJobManagerBudgetContentHeader',

  props: {
    isBudget: { type: Boolean, default: false }
  },

  methods: {
    ...mapMutations('admin/jobManager/budget', {
      updateUi: 'UPDATE_UI'
    }),

    handleOpenEditBudgetDialog() {
      this.updateUi({ createBudgetDialog: { isVisible: true } })
    },

    handleOpenCreatePayoutDialog() {
      this.updateUi({ createPayoutDialog: { isVisible: true } })
    }
  }
}
</script>
