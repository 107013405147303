import axios from 'axios'

import { formatListResponse } from '@/utils/api'

const BASE_URL = `/api/assignment`
const URLs = new Proxy(
  {
    FETCH_ALL_OPEN: `/get-open-assignments/open/active`,

    FETCH_ALL_PARTICIPANTS: `/get-participants/`,
    FETCH_ONE: `/get-detail/`,
    FETCH_ONE_FLYWEIGHT: `/flyweight-get-detail/`,

    FETCH_DETAIL_WITH_ASSIGNEE: `/get-detail-with-assignee/`,
    FETCH_DASHBOARD_IMAGE: '/get-dashboard-image/',

    FETCH_ALL_INVITEES: '/get-all-invitees/',
    FETCH_ALL_WITH_BUDGETS_AND_PAYOUTS_BY_USER_ID:
      '/get-with-budgets-and-payouts-by-user-id/',

    INVITE_USERS: `/invite-users`,

    AUTO_INVITE_AND_ACCEPT: `/auto-invite-and-accept`,

    UPDATE_ONE_SINGLE_PROPERTY: '/update-single-property'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllOpen() {
    const res = await axios.get(`${URLs.FETCH_ALL_OPEN}`)

    return formatListResponse(res)
  },

  async fetchAllParticipants({ assignmentId }) {
    const res = await axios.get(`${URLs.FETCH_ALL_PARTICIPANTS}${assignmentId}`)

    return formatListResponse(res)
  },
  async fetchOne({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE}${id}`)

    return formatListResponse(res)
  },
  async fetchOneFlyweight({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_FLYWEIGHT}${id}`)

    const { content: item, isSuccess, error } = res

    return { item, isSuccess, error }
  },

  async fetchDetailWithAssignee({ assignmentId, userId }) {
    const res = await axios.get(
      `${URLs.FETCH_DETAIL_WITH_ASSIGNEE}${assignmentId}/${userId}`
    )

    const { content, isSuccess, error } = res

    return { ...content, isSuccess, error }
  },
  async fetchDashboardImage({ distributedTeamAssignmentId }) {
    const res = await axios.get(
      `${URLs.FETCH_DASHBOARD_IMAGE}${distributedTeamAssignmentId}`
    )

    const { content, isSuccess, error } = res

    return { content, isSuccess, error }
  },
  async fetchAllInvitees({ assignmentId }) {
    const res = await axios.get(`${URLs.FETCH_ALL_INVITEES}${assignmentId}`)

    return formatListResponse(res)
  },
  async fetchAllWithBudgetsAndPayoutsByUserId({ userId }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_WITH_BUDGETS_AND_PAYOUTS_BY_USER_ID}${userId}`
    )

    return formatListResponse(res)
  },
  async inviteUsersOrTeams(payload) {
    const res = await axios.put(`${URLs.INVITE_USERS}`, payload)

    return formatListResponse(res)
  },
  async autoInviteAndAccept(payload) {
    const res = await axios.post(`${URLs.AUTO_INVITE_AND_ACCEPT}`, payload)

    const { isSuccess, error } = res

    return { isSuccess, error }
  },
  async updateOneSingleProperty(payload) {
    const res = await axios.patch(URLs.UPDATE_ONE_SINGLE_PROPERTY, payload)

    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
