import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAll = async payload => {
  let result = {}

  try {
    result = await $api.assignmentPayouts.fetchAll(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllById = async payload => {
  let result = {}

  try {
    result = await $api.assignmentPayouts.fetchAllById(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllByAssignmentBudgetId = async payload => {
  let result = {}

  try {
    result = await $api.assignmentPayouts.fetchAllByAssignmentBudgetId(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllByAssignmentBudgetIdAndPayeeUserId = async payload => {
  let result = {}

  try {
    result =
      await $api.assignmentPayouts.fetchAllByAssignmentBudgetIdAndPayeeUserId(
        payload
      )
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const createOne = async payload => {
  let result = {}

  try {
    result = await $api.assignmentPayouts.createOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.assignmentPayouts.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteOne = async payload => {
  let result = {}

  try {
    result = await $api.assignmentPayouts.deleteOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}
