<template>
  <div>
    <v-divider v-if="index" />

    <div class="w-full h-full gap-4 px-4 py-2 flex items-center">
      <div class="w-full gap-4 flex items-center">
        <span class="text-gray-400 break-keep">{{ index + 1 }}. </span>

        <div class="w-[10rem] flex flex-col">
          <span>{{ item.workFunction?.name }}</span>
          <span class="text-xs text-gray-500">Function</span>
        </div>

        <div class="w-28 flex flex-col flex-initial items-center text-center">
          <div class="gap-1 flex items-center">
            <v-icon small>mdi-calendar-account-outline</v-icon>

            {{ $formatDate(item.updateAt || item.createdAt) || '–' }}
          </div>

          <span class="text-xs text-gray-500">Updated at</span>
        </div>

        <div class="w-20 flex flex-col flex-initial items-center text-center">
          <span>{{
            formatCurrencyWithSpaces(item.assignedPaymentAmount)
          }}</span>
          <span class="text-xs text-gray-500">Assigned</span>
        </div>
        <div class="w-20 flex flex-col flex-initial items-center text-center">
          <span>{{
            formatCurrencyWithSpaces(item.revisionsPaymentAmount)
          }}</span>
          <span class="text-xs text-gray-500">Revisions</span>
        </div>
        <div class="w-20 flex flex-col flex-initial items-center text-center">
          <span>{{
            formatCurrencyWithSpaces(item.finalistPaymentAmount)
          }}</span>
          <span class="text-xs text-gray-500">Finalist</span>
        </div>
        <div class="w-20 flex flex-col flex-initial items-center text-center">
          <span>{{ formatCurrencyWithSpaces(item.winnerPaymentAmount) }}</span>
          <span class="text-xs text-gray-500">Winner</span>
        </div>

        <div class="w-32 flex flex-col flex-initial items-center text-center">
          <span class="font-semibold">{{
            formatCurrencyWithSpaces(item.totalPaymentAmount)
          }}</span>
          <span class="text-xs text-gray-500">Total</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrencyWithSpaces } from '@/utils/base'

export default {
  name: 'TenantPrincipalPayoutsListItem',

  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true }
  },

  created() {
    this.formatCurrencyWithSpaces = formatCurrencyWithSpaces
  }
}
</script>
