<template>
  <div>
    <v-divider />

    <div class="w-full h-full gap-4 px-4 py-2 flex items-center">
      <div class="w-full gap-4 flex items-center justify-end">
        <div class="w-4"></div>

        <div class="w-20 flex flex-col flex-initial items-center text-center">
          <span class="font-semibold">{{
            formatCurrencyWithSpaces(totalBudget)
          }}</span>
          <span class="text-xs text-gray-500">Total Budget</span>
        </div>

        <div class="font-semibold">–</div>

        <div class="w-20 flex flex-col flex-initial items-center text-center">
          <span class="font-semibold">{{
            formatCurrencyWithSpaces(totalCost)
          }}</span>
          <span class="text-xs text-gray-500">Total Cost</span>
        </div>

        <div class="font-semibold">=</div>

        <div
          class="w-20 flex flex-col flex-initial items-center text-center"
          :class="{
            'text-error': isRemainingNegative
          }"
        >
          <span class="font-semibold">{{
            formatCurrencyWithSpaces(remaining)
          }}</span>
          <span class="text-xs text-gray-500">Remaining</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrencyWithSpaces } from '@/utils/base'

export default {
  name: 'AdminJobManagerBudgetContentListItem',

  props: {
    budget: { type: Object, required: true },
    payouts: { type: Array, required: true }
  },

  computed: {
    totalBudget() {
      return this.budget.totalAllocated
    },

    totalCost() {
      return this.payouts.reduce(
        (acc, item) => acc + item.totalPaymentAmount,
        0
      )
    },

    remaining() {
      return this.totalBudget - this.totalCost
    },

    isRemainingNegative() {
      return this.remaining < 0
    }
  },

  created() {
    this.formatCurrencyWithSpaces = formatCurrencyWithSpaces
  }
}
</script>
