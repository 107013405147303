import throttle from 'lodash/throttle'

export default {
  inserted(el, binding) {
    const callback = throttle(binding.value, 100)
    const isWindow = binding.arg === 'window'

    let previousWidth = 0
    let previousHeight = 0

    const resizeObserver = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect

      if (width !== previousWidth || height !== previousHeight) {
        previousWidth = width
        previousHeight = height
        callback({ x: width, y: height })
      }
    })

    if (isWindow) {
      resizeObserver.observe(document.documentElement)
    } else {
      resizeObserver.observe(el)
    }

    el.__resizeObserver__ = resizeObserver
  },

  unbind(el) {
    el.__resizeObserver__.disconnect()
  }
}
