<template>
  <v-menu left :value="isVisible" :close-on-content-click="false">
    <template #activator="{ on, attrs }">
      <v-btn class="mt-auto" icon small v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item-group dense>
        <v-list-item
          v-for="(button, index) in buttons"
          :key="index"
          :color="button.color"
          @click="button.handler"
        >
          <v-list-item-icon>
            <v-icon :color="button.color">{{ button.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>{{ button.label }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'TenantMemberProfileLinksListItemMenu',

  data: () => ({
    isVisible: false
  }),

  computed: {
    buttons() {
      return [
        {
          label: 'Edit',
          color: 'secondary',
          icon: 'mdi-pencil',
          handler: this.handleEdit
        },
        {
          label: 'Delete',
          color: 'error',
          icon: 'mdi-trash-can',
          handler: this.handleDelete
        }
      ]
    }
  },

  methods: {
    handleEdit() {
      this.$emit('action-update')

      this.handleClose()
    },
    handleDelete() {
      this.$emit('action-delete')

      this.handleClose()
    },

    handleClose() {
      this.isVisible = false
    }
  }
}
</script>
