<template>
  <div
    class="m-1 bg-gray-50 border-2 border-dashed border-gray-300 rounded cursor-pointer hover:bg-gray-100"
    @click="handleCreateBudget"
  >
    <div
      class="p-8 gap-2 flex flex-col items-center justify-center text-center text-sm"
    >
      <v-icon class="!text-primary">mdi-plus</v-icon>

      <span class="font-semibold text-primary">Click to create a budget</span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'AdminJobManagerBudgetContentEmpty',

  methods: {
    ...mapMutations('admin/jobManager/budget', {
      updateUi: 'UPDATE_UI'
    }),

    async handleCreateBudget() {
      this.updateUi({ createBudgetDialog: { isVisible: true } })
    }
  }
}
</script>
